<div *ngIf="absenceEntryType != null">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="absenceEntryType.name" [maxlength]="nameInputLength" matInput placeholder="Name">
      <mat-hint align="end">{{absenceEntryType.name.length}}/{{nameInputLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input [(ngModel)]="absenceEntryType.description" [maxlength]="descriptionInputLength" matInput
             placeholder="Beschreibung">
      <mat-hint align="end">{{absenceEntryType.description.length}}/{{descriptionInputLength}}</mat-hint>
    </mat-form-field>
  </div>


  <div>
    <mat-form-field class="full-width">
      <mat-label>Ratio</mat-label>
      <input [(ngModel)]="absenceEntryType.ratio" matInput placeholder="Ratio" type="number">
    </mat-form-field>
  </div>

  <div class="full-width">
    <app-select
      placeholder="Zeittyp"
      [(selectedOption)]="absenceEntryType.kind"
      [options]="options"
    />
  </div>

  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>OK</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>
