<app-speech-bubble [arrowPosition]="'right'">
  <div class="add-workplace-title">Arbeitsort vormerken</div>
  <div class="workplace-row">
    <span [title]="workplace.country" class="select-half-width">
        <app-select
          placeholder="Staat"
          [(selectedOption)]="workplace.country"
          [options]="dialogData.countries"
          (selectedOptionChange)="onCountryChange(workplace)"
        />
    </span>

    <span [title]="workplace.county" class="select-half-width">
      <app-select
          placeholder="Region"
          [(selectedOption)]="workplace.county"
          [options]="dialogData.counties.get(workplace.country)"
          (selectedOptionChange)="validateWorkplaces()"
      />
    </span>
  </div>
  <div class="date-field decrease-height" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <div class="date-input-field" (click)="togglePopup(workplace)">
      <div class="placeholder" *ngIf="workplace.dateRangePicker.from == null; else dateRange ">
        Startdatum wählen
      </div>
    </div>
    <button (click)="togglePopup(workplace)" class="calendar-toggle-button" mat-mini-fab>
      <mat-icon>event</mat-icon>
    </button>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="workplace.popupVisible"
    (overlayOutsideClick)="togglePopup(workplace)"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  >
    <div class="popup">
      <div class="calendar">
        <div class="calendar-navigation">
          <mat-icon (click)="prevMonth()" class="clickable"
                    title="Vorheriger Monat">
            chevron_left
          </mat-icon>
          <div>
            {{month | getMonthLabel}}
          </div>
          <mat-icon (click)="nextMonth()" class="clickable"
                    title="Nächster Monat">
            chevron_right
          </mat-icon>
        </div>
        <app-date-picker (selectedDate)="onSelectDate($event,workplace)"
                         [currentMonthObservable]="calendarMonth$"
                         [dateRangePicker]="workplace.dateRangePicker"
                         [ignoreConstraints]="true"
                         [possibleToSelectDateInPast]="false"></app-date-picker>
      </div>
    </div>
  </ng-template>
  <ng-template #dateRange>
    <div>
      {{formatDate(workplace.dateRangePicker.from)}}
    </div>
  </ng-template>
  <div *ngIf="!duplicateDate && !noWorkplaceSelected; else errorText"
       class="duplicate-workplace-container user-row"></div>
  <ng-template #errorText>
    <div class="duplicate-workplace-container">
      <div *ngIf="duplicateDate">Bitte gib ein Startdatum an, das sich von dem Aktuellen unterscheidet.
      </div>
      <div *ngIf="noWorkplaceSelected">Bitte gib einen vollständigen Arbeitsort an.</div>
    </div>
  </ng-template>
  <div class="future-workplace-action-btn">
    <button (click)="cancel()" mat-button class="cancel-and-save-btn">Abbrechen</button>
    <button (click)="save()"
            [disabled]="duplicateDate || noWorkplaceSelected"
            color="primary" mat-flat-button class="cancel-and-save-btn">Übernehmen
    </button>
  </div>
</app-speech-bubble>
