<div *ngIf="currentTopic != null" class="dialog column">

  <div class="headline highlight">
    <p>Stunden eines Themas umbuchen</p>
  </div>

  <div class=" infotext row">
    <div>Welche Stunden sollen umgebucht werden? Wähle bitte</div>
    <div class="highlight">&nbsp;&nbsp;Mitarbeiter,&nbsp;</div>
    <div>&nbsp;und&nbsp;</div>
    <div class="highlight">&nbsp;Zeitraum&nbsp;</div>
    <div>&nbsp;aus.</div>
  </div>

  <div class="input-row">
    <div class="left-input">
      <app-user-selection   [usersToFilter]="userOnTopic" (userSelected)="onUserSelected($event)"></app-user-selection>
    </div>
    <div class="right-input">
      <app-daterange-picker (selectedDateRange)="onDateRangeSelect($event)"></app-daterange-picker>
    </div>
  </div>

  <div class="recordGroup">
    <div class="left">
      <div class="recordRow">
        <div class="icon">
          <img [src]="currentTopicIcon"
               alt="Customer logo"
               class="cell-logo">
        </div>
        <div class="task">
          <div class="auftrag">{{currentTopic.customer.name}} / {{currentTopic.assignment.name}}</div>
          <div class="thema">
            <div>
              {{currentTopic.topic.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="calculated-hours" *ngIf="!currentTopicDataLoading; else topicDataLoadingTemplate ">
        <div class="column center">
          <div>Zeitraum</div>
          <div>Stunden</div>
        </div>

        <div class="column center">
          <div>Gebucht</div>
          <div>{{formatToTimeValue(calculatedcurrentTopicData.totalTime)}}</div>
        </div>

        <div class="column center"  *ngIf="selectedUser != null">
          <div>Mitarbeiter</div>
          <div>{{formatToTimeValue(calculatedcurrentTopicData.employeeBookedTime)}}</div>
        </div>

        <div class="column center">
          <div>Änderung</div>
          <div class="green">{{formatToTimeValue(calculatedcurrentTopicData.change)}}</div>
        </div>
      </div>
    </div>
  </div>


  <div class="infotext row">
    <div>Auf welches Thema sollen die Stunden umgebucht werden? Wähle bitte ein</div>
    <div class="highlight">&nbsp;Thema&nbsp;</div>
    <div>&nbsp;aus.</div>
  </div>

  <div class="recordGroup">
    <div class="left">
      <div class="topic-chooser-input-container" *ngIf="userSelectedTopic == null ">
        <app-smart-adding-input (selectedTopic)="onTopicSelected($event)" [actionRequired]="false" [excludeTopicIds]="[currentTopic.topic.id]"
                                [isSubmitted]="false"></app-smart-adding-input>
      </div>
      <div class="recordRow" *ngIf="userSelectedTopic != null">
        <div class="icon">
          <img [src]="userSelectedTopic.icon"
               alt="Customer logo"
               class="cell-logo">
        </div>
        <div class="task">
          <div class="auftrag_editable">{{userSelectedTopic.label}}</div>
          <div class="thema_editable">
            <div>
              {{userSelectedTopic.subLabel}}
            </div>
            <button mat-mini-fab (click)="resetSelecteddTopic()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="right" *ngIf="userSelectedTopic != null">
      <div class="calculated-hours" *ngIf="!userSelectedTopicDataLoading; else topicDataLoadingTemplate ">
        <div class="column center">
          <div>Zeitraum</div>
          <div>Stunden</div>
        </div>
        <div class="column center">
          <div>Gebucht</div>
          <div>{{fromatTimeValue(calculatedUserSelectedTopicData.totalTime)}}</div>
        </div>
        <div class="column center"  *ngIf="selectedUser != null">
          <div>Mitarbeiter</div>
          <div>{{fromatTimeValue(calculatedUserSelectedTopicData.employeeBookedTime)}}</div>
        </div>
        <div class="column center">
          <div>Änderung</div>
          <div class="green">{{fromatTimeValue(calculatedUserSelectedTopicData.change)}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-bar">
    <button (click)="cancel()"  mat-button>Abbrechen</button>
    <button
      [disabled]="(userSelectedTopic== null|| !isTopicOptionEntity(userSelectedTopic))"
      (click)="confirm()" color="primary" mat-raised-button>Umbuchen
    </button>
  </div>
</div>

<ng-template #topicDataLoadingTemplate>
  <div class="calculated-hours">
    <div class="column center">
      <div>&nbsp;</div>
      <div>Stunden</div>
    </div>

    <div class="column center">
      <div>Gebucht</div>
      <div>
        <app-loading-spinner [loading]="true"></app-loading-spinner>
      </div>
    </div>

    <div class="column center">
      <div>Mitarbeiter</div>
      <div>
        <app-loading-spinner [loading]="true"></app-loading-spinner>
      </div>
    </div>

    <div class="column center">
      <div>Änderung</div>
      <div>
        <app-loading-spinner [loading]="true"></app-loading-spinner>
      </div>
    </div>
  </div>
</ng-template>
