import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogReturnType } from '../../../shared-components/dialog-return-type';
import { MergedTopic } from '../../../services/merged-topics.service';
import { UserEntry } from '../../../services/repository/user.service';
import { SmartInputOption } from '../../../services/smart-input-option.service';

@Component({
  selector: 'app-topic-confirm-rebook-dialog',
  templateUrl: './topic-rebook-confirm-dialog.component.html',
  styleUrls: ['./topic-rebook-confirm-dialog.component.scss'],
})
export class RebookTopicConfirmDialogComponent implements OnInit, OnDestroy {
  infoIcon = '/assets/icons/outline-info-red-24px.svg';
  dialogData = null;

  constructor(
    private dialogRef: MatDialogRef<RebookTopicConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TopicRebookConfirmDialogData,
  ) {}

  ngOnInit(): void {
    this.dialogData = this.data;
  }

  ngOnDestroy(): void {}

  cancel(): void {
    this.dialogRef.close({
      dialogAction: DialogReturnType.CANCEL,
    });
  }

  rebook(): void {
    this.dialogRef.close({
      dialogAction: DialogReturnType.CONFIRM,
    });
  }
}

interface TopicRebookConfirmDialogData {
  mergedTopic: MergedTopic;
  selectedUser: UserEntry;
  selectedTopic: SmartInputOption;
}
