import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {WorkplacesService, WorkplacesTableDate} from '../../services/repository/workplaces.service'
import {DateUtils} from '../../util/date-utils'
import {BehaviorSubject, Observable} from 'rxjs'
import {DialogReturnType} from '../../shared-components/dialog-return-type'
import {DateRangePicker} from '../../util/date-range-picker'

@Component({
    selector: 'app-add-workplace-dialog',
    templateUrl: './add-workplace-dialog.component.html',
    styleUrls: ['./add-workplace-dialog.component.scss']
})
export class AddWorkplaceDialogComponent implements OnInit {

    workplace: WorkplacesTableDate
    duplicateDate: boolean
    noWorkplaceSelected: boolean
    index = 0
    private _month$: BehaviorSubject<Date>

    constructor(
        private workplacesService: WorkplacesService,
        private dialogRef: MatDialogRef<AddWorkplaceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData) {
        this.dialogData = dialogData
    }

    get month(): Date {
        return new Date(this._month$.value)
    }

    get calendarMonth$(): Observable<Date> {
        return this._month$
    }

    ngOnInit(): void {
        this.initializeSubjects()
        this.index = this.dialogData.workplace.findIndex(workplace => workplace.currentWorkplace)
        this.workplace = {
            id: null,
            userFK: this.dialogData.workplace[this.index].userFK,
            startDate: DateUtils.dateToString(DateUtils.today),
            country: this.dialogData.workplace[this.index].country,
            county: this.dialogData.workplace[this.index].county,
            popupVisible: false,
            dateRangePicker: new DateRangePicker(DateUtils.today),
        } as WorkplacesTableDate
        this.validateWorkplaces()
    }

    getCountry(key: string): string {
        return this.workplacesService.getCountry(key)
    }

    getCounty(key: string, country: string): string {
        return this.workplacesService.getCounty(key, country)
    }

    onCountryChange(data: WorkplacesTableDate): void {
        data.county = ''
        this.validateWorkplaces()
    }

    filteredFormData(country: string): string[] {
        if (!country) {
            return
        }
        return [...this.workplacesService.getCountyMapping(country).values()]
    }

    prevMonth(): void {
        const newMonth = DateUtils.getPreviousMonth(this.month)
        this._month$.next(newMonth)
    }

    nextMonth(): void {
        const newMonth = DateUtils.getNextMonth(this.month)
        this._month$.next(newMonth)
    }

    formatDate(date): string {
        return DateUtils.formatDate(date)
    }

    onSelectDate(selectedDate, data): void {
        data.dateRangePicker.reset()
        data.dateRangePicker.pickDate(selectedDate)
        data.startDate = DateUtils.dateToString(selectedDate)
        this.togglePopup(data)
        this.validateWorkplaces()
    }

    togglePopup(data: WorkplacesTableDate): void {
        data.popupVisible = !data.popupVisible
    }

    checkIfDuplicateExists(newWorkplace: string, oldWorkplace): boolean {
        return newWorkplace === oldWorkplace
    }

    validateWorkplaces(): void {
        this.duplicateDate = this.dialogData.workplace.some(workplace =>
            this.checkIfDuplicateExists(this.workplace.startDate, workplace.startDate)
        )
        this.noWorkplaceSelected = this.workplace.county === '' || this.workplace.country === ''
    }

    cancel(): void {
        this.dialogRef.close({dialogAction: DialogReturnType.CANCEL})
    }

    save(): void {
        this.validateWorkplaces()
        if (!this.duplicateDate && !this.noWorkplaceSelected) {
            const result = {
                workplace: this.workplacesService.workplacesTableDataToWorkplaces(this.workplace),
                dialogAction: DialogReturnType.SAVE
            }
            this.dialogRef.close(result)
        }
    }

    private initializeSubjects(): void {
        const date = DateUtils.today
        this._month$ = new BehaviorSubject<Date>(date)
    }

}
