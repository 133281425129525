import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasedataEntry} from '../entity-editor/entity-editor.component';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AbsenceEntryTypeEntity, AbsenceEntryTypeService} from '../../services/repository/absence-entry-type.service';
import {AbsenceEntryTypeEditorDialogComponent} from '../absence-entry-type-editor-dialog/absence-entry-type-editor-dialog.component';
import {BaseDataEntryService} from '../../services/basedata-entry.service';
import {KeyValueService} from '../../services/repository/key-value.service';
import {first} from 'rxjs/operators';

const ORDER_KEY = 'order_key';

@Component({
  selector: 'app-absence-entry-type-editor',
  templateUrl: './absence-entry-type-editor.component.html',
  styleUrls: ['./absence-entry-type-editor.component.scss']
})
export class AbsenceEntryTypeEditorComponent implements OnInit, OnDestroy {

  @Input() showArchived = false;

  absenceEntryTypes: BasedataEntry[] = [];
  selectedAbsenceEntryType: BasedataEntry;
  private absenceEntryServiceSubscription: Subscription;

  constructor(
    private absenceEntryTypeService: AbsenceEntryTypeService,
    private dialog: MatDialog,
    private keyValueService: KeyValueService
  ) {
  }

  // TODO bei jedem Drop wird die Methode initAbsenceEntryTypeObservable() 3-4 mal aufgerufen
  ngOnInit(): void {
    this.keyValueService.all$.pipe(
      first((value => value !== undefined))
    ).subscribe(() => {
      this.initAbsenceEntryTypeObservable();
    });
  }

  ngOnDestroy(): void {
    this.absenceEntryServiceSubscription.unsubscribe();
  }

  newAbsenceEntryType(): void {
    this.dialog.open(AbsenceEntryTypeEditorDialogComponent, {
      width: '500px',
      data: {id: -1, addTimeEntryType: true}
    });
  }

  archiveAbsenceEntryType(entry: BasedataEntry): void {
    BaseDataEntryService.openArchiveDialog(this.dialog, entry).subscribe(shouldArchive => {
      if (shouldArchive) {
        this.absenceEntryTypeService.delete(this.absenceEntryTypeService.getById(entry.id));
        entry.archived = true;
        this.sortAbsenceTypeArray();
      }
    });
  }

  editAbsenceEntryType(entry: BasedataEntry): void {
    this.selectedAbsenceEntryType = entry;

    this.dialog.open(AbsenceEntryTypeEditorDialogComponent, {
      width: '500px',
      data: {id: this.selectedAbsenceEntryType.id, addTimeEntryType: false}
    });
  }

  selectAbsenceEntryType(entry: BasedataEntry): void {
    this.selectedAbsenceEntryType = entry;
  }

  restoreAbsenceEntryType(entry: BasedataEntry): void {
    this.absenceEntryTypeService.restore(this.absenceEntryTypeService.getById(entry.id));
  }

  updateAbsenceEntryTypes(): void {
    const entriesOrder =
      this.absenceEntryTypes
        .filter(entry => !entry.archived)
        .map(entry => entry.id)
        .join(' ');

    const archivedEntriesOrder =
      this.absenceEntryTypeService
        .all
        .filter(entry => entry.archived)
        .map(entry => entry.id)
        .join(' ');

    const separator =
      (archivedEntriesOrder.length === 0 || entriesOrder.length === 0)
        ? ''
        : ' ';

    this.keyValueService.updateSystemWide({
      userFK: null,
      key: ORDER_KEY,
      value: entriesOrder + separator + archivedEntriesOrder
    });
  }

  private initAbsenceEntryTypeObservable(): void {
    this.absenceEntryServiceSubscription =
      this.absenceEntryTypeService.all$.subscribe(list => {
        this.absenceEntryTypes = [];
        const baseDataEntries = this.toBasedataEntries(list);
        this.fillAbsenceEntryTypes(baseDataEntries);
      });
    this.absenceEntryTypeService.requestAll();
  }

  private toBasedataEntries(list: AbsenceEntryTypeEntity[]): BasedataEntry[] {
    return list
      .filter(absenceEntryType => (this.showArchived) || (!absenceEntryType.archived))
      .map(absenceEntryType => {
        return {
          id: absenceEntryType.id,
          label: absenceEntryType.name,
          archived: absenceEntryType.archived
        } as BasedataEntry;
      });
  }

  private sortAbsenceTypeArray(): void {
    this.absenceEntryTypes.sort((a, b) => {
      if (a.archived && b.archived) {
        if (a.label < b.label) {
          return -1;
        } else if (a.label > b.label) {
          return 1;
        }
      } else if (a.archived || b.archived) {
        return (a.archived) ? 1 : -1;
      }
      return 0;
    });
  }

  private fillAbsenceEntryTypes(entries: BasedataEntry[]): void {
    const orderKeyValue = this.keyValueService.get(ORDER_KEY);
    if (orderKeyValue !== undefined) {
      const order = orderKeyValue.value;
      const orderArray: number[] = order.split(' ').map(str => +str);

      const allAbsenceEntryTypes = this.absenceEntryTypeService.all;
      if (allAbsenceEntryTypes.length > orderArray.length) {
        allAbsenceEntryTypes
          .filter(entry => !orderArray.includes(entry.id))
          .forEach((unorderedEntry) => {
            orderArray.push(unorderedEntry.id);
          });

        this.keyValueService.updateSystemWide({
          userFK: null,
          key: ORDER_KEY,
          value: orderArray.join(' ')
        });
      }

      if (entries.length < orderArray.length) {
        orderArray
          .map((orderNum, index) => {
            return {orderNum, index};
          })
          .filter((orderNumInfo) => !entries.map(entry => entry.id).includes(orderNumInfo.orderNum))
          .sort((firstEntry, secondEntry) => firstEntry.index > secondEntry.index ? -1 : 1)
          .forEach((removeOrderNum) => {
            orderArray.splice(removeOrderNum.index, 1);
          });
      }

      orderArray.forEach(orderIndex => {
        const entry = entries.find(e => orderIndex === e.id);
        if (entry !== undefined) {
          this.absenceEntryTypes.push(entry);
        }
      });
    } else {
      this.absenceEntryTypes = entries;

      this.keyValueService.updateSystemWide({
        userFK: null,
        key: ORDER_KEY,
        value: this.absenceEntryTypeService.all.map(entry => entry.id).join(' ')
      });
    }

    this.sortAbsenceTypeArray();
  }
}


export interface AbsenceEntryTypeDialogData {
  id: number;
  addTimeEntryType: boolean;
}
