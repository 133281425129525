import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {HttpClient, HttpParams} from '@angular/common/http'
import {DateRangePicker} from '../../util/date-range-picker'
import {environment} from '../../../environments/environment'


const COUNTY_MAPPING_DE = new Map<string, string>([
  ['Baden-Württemberg', 'BW'],
  ['Bayern', 'BY'],
  ['Berlin', 'BE'],
  ['Brandenburg', 'BB'],
  ['Bremen', 'HB'],
  ['Hamburg', 'HH'],
  ['Hessen', 'HE'],
  ['Mecklenburg-Vorpommern', 'MV'],
  ['Niedersachsen', 'NI'],
  ['Nordrhein-Westfalen', 'NW'],
  ['Rheinland-Pfalz', 'RP'],
  ['Saarland', 'SL'],
  ['Sachsen', 'SN'],
  ['Saarland', 'SL'],
  ['Sachsen-Anhalt', 'ST'],
  ['Schleswig-Holstein', 'SH'],
  ['Thüringen', 'TH'],
])

const COUNTY_MAPPING_CH = new Map<string, string>([
  ['Aargau', 'AG'],
  ['Appenzell Ausserrhoden', 'AR'],
  ['Appenzell Innerrhoden', 'AI'],
  ['Basel-Landschaft', 'BL'],
  ['Basel-Stadt', 'BS'],
  ['Bern', 'BE'],
  ['Freiburg', 'FR'],
  ['Genève', 'GE'],
  ['Glarus', 'GL'],
  ['Graubünden', 'GR'],
  ['Jura', 'JU'],
  ['Luzern', 'LU'],
  ['Neuchâtel', 'NE'],
  ['Nidwalden', 'NW'],
  ['Obwalden', 'OW'],
  ['Sankt Gallen', 'SG'],
  ['Schaffhausen', 'SH'],
  ['Schwyz', 'SZ'],
  ['Solothurn', 'SO'],
  ['Thurgau', 'TG'],
  ['Ticino', 'TI'],
  ['Uri', 'UR'],
  ['Wallis', 'VS'],
  ['Vaud', 'VD'],
  ['Zug', 'ZG'],
  ['Zürich', 'ZH'],
])

const COUNTY_MAPPING_AT = new Map<string, string>([
  ['Burgenland', '1'],
  ['Kärnten', '2'],
  ['Niederösterreich', '3'],
  ['Oberösterreich', '4'],
  ['Salzburg', '5'],
  ['Steiermark', '6'],
  ['Tirol', '7'],
  ['Vorarlberg', '8'],
  ['Wien', '9'],
])

const COUNTRY_MAPPING = new Map<string, string>([
  ['Deutschland', 'DE'],
  ['Österreich', 'AT'],
  ['Schweiz', 'CH']
])

const COUNTRY_COUNTY_MAPPING = new Map<string, Map<string, string>>([
  ['DE', COUNTY_MAPPING_DE],
  ['AT', COUNTY_MAPPING_AT],
  ['CH', COUNTY_MAPPING_CH]
])

@Injectable({
  providedIn: 'root'
})
export class WorkplacesService {
  private repoURL: string

  constructor(private httpClient: HttpClient) {
    this.repoURL = `${environment.serverUrl}/workplaces`
  }

  private static swapKeyWithValue(map: Map<string, string>): Map<string, string> {
    const swappedMap = new Map()
    for (const [key, value] of map) {
      swappedMap.set(value, key)
    }
    return swappedMap
  }

  getCountryMapping(): Map<string, string> {
    return COUNTRY_MAPPING
  }

  getShortCountryMapping(): Map<string, string> {
    return WorkplacesService.swapKeyWithValue(COUNTRY_MAPPING)
  }

  getCountry(key: string): string {
    return this.getShortCountryMapping().get(key)
  }

  getCountyMapping(country: string): Map<string, string> {
    return this.getCountyMappingForCountry(country)
  }

  getShortCountyMapping(country: string): Map<string, string> {
    return WorkplacesService.swapKeyWithValue(this.getCountyMappingForCountry(country))
  }

  getCounty(key: string, country: string): string {
    return this.getShortCountyMapping(country).get(key)
  }

  getCountyMappingForCountry(shortCountry: string): Map<string, string> {
    return COUNTRY_COUNTY_MAPPING.get(shortCountry)
  }

  getCountyMappingForAll(): Map<string, Map<string, string>> {
    return COUNTRY_COUNTY_MAPPING
  }

  workplacesById(userId: number): Observable<Workplaces[]> {
    let params = new HttpParams()
    params = params.append('userId', userId.toString())

    return this.httpClient.get<Workplaces[]>(this.repoURL, {params})
  }

  myWorkplaces(): Observable<Workplaces[]> {
    return this.httpClient.get<Workplaces[]>(`${this.repoURL}/me`)
  }

  addWorkplaces(workplace: Workplaces): void {
    this.httpClient.post(this.repoURL, workplace).subscribe()
  }

  updateWorkplaces(workplace: Workplaces): void {
    this.httpClient.put(`${this.repoURL}/${workplace.id}`, workplace).subscribe()
  }

  deleteWorkplaces(workplaceId: number): void {
    this.httpClient.delete(`${this.repoURL}/${workplaceId}`).subscribe()
  }

  workplacesTableDataToWorkplaces(workplace: WorkplacesTableDate): Workplaces {
    return {
      id: workplace.id,
      userFK: workplace.userFK,
      startDate: workplace.startDate,
      country: workplace.country,
      county: workplace.county,
    } as Workplaces
  }
}


export interface Workplaces {
  id?: number
  userFK?: number
  startDate: string
  country: string
  county: string
}


export interface WorkplacesTableDate extends Workplaces {
  popupVisible?: boolean
  dateRangePicker?: DateRangePicker
  currentWorkplace?: boolean
  futureWorkplace?: boolean
}
