import {Component} from '@angular/core';
import {CustomerService} from '../../services/repository/customer.service';
import {TopicService} from '../../services/repository/topic.service';
import {TimeEntryTypeService} from '../../services/repository/time-entry-type.service';
import {AssignmentService} from '../../services/repository/assignment.service';
import {AbsenceEntryTypeService} from '../../services/repository/absence-entry-type.service';

@Component({
  selector: 'app-time-types-editor',
  templateUrl: './time-types-editor.component.html',
  styleUrls: ['./time-types-editor.component.scss']
})
export class TimeTypesEditorComponent {
  showArchived: boolean;

  constructor(private assignmentService: AssignmentService,
              private customerService: CustomerService,
              private topicService: TopicService,
              private timeEntryTypeService: TimeEntryTypeService,
              private absenceEntryTypeService: AbsenceEntryTypeService) {
  }

  updateShowArchived(): void {
    this.showArchived = !this.showArchived;
    this.assignmentService.requestAll();
    this.customerService.requestAll();
    this.topicService.requestAll();
    this.absenceEntryTypeService.requestAll();
    this.timeEntryTypeService.requestAll();
  }
}
