<div *ngIf="assignment != null">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="assignment.name" [maxlength]="assignmentNameMaxLength" matInput placeholder="Name">
      <mat-hint align="end">{{assignment.name.length}}/{{assignmentNameMaxLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input [(ngModel)]="assignment.description" [maxlength]="assignmentDescriptionMaxLength" matInput
             placeholder="Beschreibung">
      <mat-hint align="end">{{assignment.description.length}}/{{assignmentDescriptionMaxLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Vertragsnummer</mat-label>
      <input [(ngModel)]="assignment.contractNumber" [maxlength]="assignmentContractNumberMaxLength" matInput
             placeholder="Vertragsnummer">
      <mat-hint align="end">{{assignment.contractNumber.length}}/{{assignmentContractNumberMaxLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="full-width supervisor-row">
    <div class="field-name">Verantwortlicher</div>
    <div class="field-value">
      <app-select
        *ngIf="canEditResponsibleSupervisor(); else readonlySupervisorTemp"
        [(selectedOption)]="assignment.responsibleSupervisorFK"
        [options]="allSupervisors"
        [compareSelectedWithOptions]="isSupervisorEquals"
        placeholder="Verantwortlicher"
      />
      <ng-template #readonlySupervisorTemp>
        <div class="row-name">{{assignment.responsibleSupervisorFK | getUserNameFromUserList: allSupervisors}}</div>
      </ng-template>
    </div>


  </div>

  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>OK</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>
