<div id="content">
  <div class="global__scroll-container">
    <div class="basedata">
      <div *ngIf="buttons.length > 0 " class="tab-container overlay">
        <app-toggle-button (onToggle)="onToggle($event)"
                           [toggleButtonInfos]="buttons"
        ></app-toggle-button>
      </div>

      <div class="content-holder">
        <div *ngIf="content == TOPICS || content == ''" class="horizontal-layout">
          <app-topics-editor></app-topics-editor>
        </div>

        <div *ngIf="content == TIME_TYPES">
          <app-time-types-editor></app-time-types-editor>
        </div>

        <div *ngIf="content == USERS">
          <app-users-editor></app-users-editor>
        </div>
      </div>
    </div>
  </div>
</div>
