<div *ngIf="timeEntryType != null">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="timeEntryType.name" [maxlength]="nameInputLength" matInput placeholder="Name">
      <mat-hint align="end" style="padding-right: 10px">{{timeEntryType.name.length}}
        /{{nameInputLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input [(ngModel)]="timeEntryType.description" [maxlength]="descriptionInputLength" matInput
             placeholder="Beschreibung">
      <mat-hint align="end" style="padding-right: 10px">{{timeEntryType.description.length}}
        /{{descriptionInputLength}}</mat-hint>
    </mat-form-field>
  </div>


  <div>
    <mat-form-field class="full-width">
      <mat-label>Ratio</mat-label>
      <input [(ngModel)]="timeEntryType.ratio" matInput placeholder="Ratio" type="number">
    </mat-form-field>
  </div>

  <div class="full-width">
    <app-select
      placeholder="Zeittyp"
      [(selectedOption)]="timeEntryType.type"
      [options]="options"
    />
  </div>


  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>OK</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>
