<div class="flex-message-box">
  <div class="flex-message-box-title">
    <div class="header-topic">{{ data.user.name }}</div>
    <div *ngIf="data.type | isBaseDataType" class="action-container">
      <button (click)="anonymizeUser(data.user)" class="action-button">Anonymisieren</button>
    </div>
  </div>
  <div class="global__scroll-container">
    <div class="flex-message-box-content scroll-container">
      <div class="user-row">
        <div class="field-name">
          Start
        </div>
        <div class="field-value">
          {{ data.user.workStart | formatDate }}
        </div>
      </div>
      <div class="user-row">
        <div class="field-name">
          Verantwortlicher
        </div>
        <div class="field-value">
          <app-select *ngIf="data.type | isBaseDataType; else showSupervisorName"
                      placeholder="Verantwortlicher"
                      [options]="supervisorOptions"
                      [(selectedOption)]="user.supervisor"
                      [compareSelectedWithOptions]="isSupervisorEquals"
          />
          <ng-template #showSupervisorName class="field-value">
            {{ user.supervisorName }}
          </ng-template>
        </div>
      </div>
      <div class="user-row">
        <div class="field-name">
          Nachweis
        </div>
        <div class="field-value">
          <app-select *ngIf="data.type | isBaseDataType; else showTimeSheetStatus"
                      placeholder="Nachweis"
                      [(selectedOption)]="user.timeSheetStatus"
                      [options]="timeSheetStatusOptions"
          />
          <ng-template #showTimeSheetStatus class="field-value">
            {{ user.timeSheetStatus | statusDisplayName }}
          </ng-template>
        </div>
      </div>
      <div class="user-row">
        <div class="field-name">
          Dienstleister
        </div>
        <div class="field-value">
          {{ data.user.companyName != null && data.user.companyName !== '' ? data.user.companyName : '---' }}
        </div>
      </div>
      <div class="user-row">
        <div class="field-name">
          E-Mail (Anmeldung)
        </div>
        <div class="field-value">
          {{ data.user.adRef }}
        </div>
      </div>
      <div class="user-row bold-description">
        <div class="field-name">
          Kontaktdaten
        </div>
      </div>
      <div *ngIf="!(type | isBaseDataType)">
        Bei Angabe einer E-Mail (Kontakt) Adresse wird Timebox Informationen an diese senden, statt an der E-Mail
        (Anmeldung) Adresse
      </div>
      <div class="user-row group-elements">
        <div class="field-name">
          E-Mail (Kontakt)
        </div>
        <form #userEditForm=ngForm class="field-value">
          <mat-form-field *ngIf="canEditEmail; else showMail" class="email-input">
            <input matInput type=email pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$' [(ngModel)]="user.email"
                   name="email">
          </mat-form-field>
        </form>
        <ng-template #showMail>
          <div class="field-value">
            {{ data.user.email != null && data.user.email !== '' ? data.user.email : '---' }}
          </div>
        </ng-template>
      </div>
      <div class="user-row workplaces-row">
        <div class="field-name bold-description">
          Arbeitsorte
        </div>
        <ng-container *ngIf="countFutureWorkplaces() && (type | isBaseDataType)">
          <button class="action-button add-workplace-btn"
                  (dialogClosed)="onAfterAddWorkplaceDialogClosed($event)"
                  [appTooltipDialog]="addWorkplaceDialog"
                  [dialogData]="{workplace: workplacesDialogData, countries: countries, counties: counties}"
                  [dialogPosition]="'left'">
            <mat-icon>add</mat-icon>
            <div>Vormerkung</div>
          </button>
        </ng-container>
      </div>
      <div *ngFor="let data of workplacesDialogData">
        <ng-container *ngIf="data.currentWorkplace">
          <div class="user-row group-elements">
            <div class="field-name">
              Arbeitsort
            </div>
            <ng-container *ngIf="(type | isBaseDataType); else currentWorkplaceReadOnly">
              <div *ngIf="data.id !== -1; else initialWorkplace" class="field-value current-workplace">
                <span [title]="data.country" class="select-half-width">

                  <app-select
                    placeholder="Staat"
                    [(selectedOption)]="data.country"
                    [options]="countries"
                    (selectedOptionChange)="onCountryChange(data)"
                  />
                  </span>
                <span [title]="data.county" class="select-half-width">
                  <app-select
                    placeholder="Region"
                    [(selectedOption)]="data.county"
                    [options]="counties.get(data.country)"
                    (selectedOptionChange)="validateWorkplaces()"
                  />
                  </span>
              </div>
              <ng-template #initialWorkplace>
                <div class="field-value">{{ getCountry(data.country) }}, {{ getCounty(data.county, data.country) }}
                  (initial)
                </div>
              </ng-template>
            </ng-container>
            <ng-template #currentWorkplaceReadOnly>
              <div class="field-value">{{ getCountry(data.country) }}, {{ getCounty(data.county, data.country) }}</div>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngIf="data.futureWorkplace">
          <div class="user-row group-elements">
            <div class="field-name">
              Vormerkung
            </div>
            <div class="field-value future-workplace">
              <div
                title="{{getCountry(data.country)}}, {{getCounty(data.county, data.country)}} (Start: {{formatDate(data.startDate)}})"
                class="future-workplace-value">
                <div class="future-workplace-country-county">
                  {{ getCountry(data.country) }}, {{ getCounty(data.county, data.country) }}
                </div>
                <div class="future-workplace-start">(Start: {{ formatDate(data.startDate) }})</div>
                <ng-container *ngIf="(type | isBaseDataType)">
                  <button (click)="delete(data)" mat-mini-fab title="Arbeitort löschen"
                          class="future-workplace-start">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="duplicate-workplace-container user-row">
    <div *ngIf="noWorkplaceSelected">Bitte gib einen vollständigen Arbeitsort an.</div>
  </div>
  <div class="flex-message-box-actions"
       *ngIf="(type | isBaseDataType) || canEditEmail; else showClose">
    <button (click)="cancel()" class="cancel-button" mat-button>Abbrechen</button>
    <button [disabled]="!userEditForm.valid || noWorkplaceSelected"
            (click)="send()" color="primary" mat-flat-button>Speichern
    </button>
  </div>
  <ng-template #showClose>
    <div class="flex-message-box-actions">
      <button (click)="cancel()" class="cancel-button" mat-button>Schließen</button>
    </div>
  </ng-template>
</div>
