import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {DialogReturnType} from '../../shared-components/dialog-return-type'
import {
  mapSortStateToNumber,
  Permission,
  TimeSheetStatus,
  TimeSheetStatusNames,
  User,
  UserEntry,
  UserService,
  UserStatus
} from '../../services/repository/user.service'
import {UsersAnonDialogComponent} from '../users-anon-dialog/users-anon-dialog.component'
import {Subject, takeUntil} from 'rxjs'
import {SubmitMonthService, SubmitType} from '../../services/repository/submit-month.service'
import {Workplaces, WorkplacesService, WorkplacesTableDate} from '../../services/repository/workplaces.service'
import {AddWorkplaceDialogComponent} from '../add-workplace-dialog/add-workplace-dialog.component'
import {DateUtils} from '../../util/date-utils'
import {SelectOption} from '../../shared-components/select/select.component'


@Component({
  selector: 'app-users-edit-dialog',
  templateUrl: './users-edit-dialog.component.html',
  styleUrls: ['./users-edit-dialog.component.scss']
})
export class UsersEditDialogComponent implements OnInit, OnDestroy {

  timeSheetStatusOptions: SelectOption<TimeSheetStatus>[] = []

  supervisorOptions: SelectOption<User>[] = []
  names = TimeSheetStatusNames
  user: UserEntry
  type: UsersEditDialogType
  canEditEmail = false

  workplacesDialogData: WorkplacesTableDate []
  initialWorkplaces: Workplaces []
  countries: SelectOption<string>[]
  counties: Map<string, SelectOption<string>[]>
  noWorkplaceSelected = false

  addWorkplaceDialog = AddWorkplaceDialogComponent

  private destroy$ = new Subject<void>()

  constructor(private dialogRef: MatDialogRef<UsersEditDialogComponent>,
              private dialog: MatDialog,
              private userService: UserService,
              private submitMonthService: SubmitMonthService,
              private workplacesService: WorkplacesService,
              @Inject(MAT_DIALOG_DATA) public data: UsersEditDialogData) {
  }

  ngOnInit(): void {

    this.timeSheetStatusOptions =
      Object.keys(TimeSheetStatus).map(value => {
        return {
          displayName: TimeSheetStatusNames[value],
          value: TimeSheetStatus[value]
        }
      })


    this.user = {
      ...this.data.user
    }
    this.type = this.data.type


    this.userService.allSupervisor$
      .pipe(takeUntil(this.destroy$))
      .subscribe(supervisors => {
        this.supervisorOptions = supervisors.map(supervisor => {
          return {
            typeId: 0,
            displayName: `${supervisor.lastName}, ${supervisor.firstName}`,
            value: supervisor
          }
        })
      })

    this.dialogRef.updatePosition({top: '10vh'})
    this.checkChangeContactEmailPermission()

    if (this.type === UsersEditDialogType.BaseData) {
      this.workplacesService.workplacesById(this.user.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            this.initializeWorkplaceArrays(data)
          },
          error: error => console.error('Error fetching workplaces by id', error)
        })
    } else {
      this.workplacesService.myWorkplaces()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            this.initializeWorkplaceArrays(data)
          },
          error: error => console.error('Error fetching users workplaces', error)
        })
    }

    this.countries = [...this.workplacesService.getCountryMapping().values()].map(country => {
      return {
        displayName: this.getCountry(country),
        value: country
      }
    })
    this.setCounties()
  }

  getCountry(key: string): string {
    return this.workplacesService.getCountry(key)
  }

  getCounty(key: string, country: string): string {
    return this.workplacesService.getCounty(key, country)
  }

  initializeWorkplaceArrays(data: Workplaces[]): void {
    const currentAndFutureWorkplaces = this.updateWorkplaces(data)
    this.workplacesDialogData = currentAndFutureWorkplaces.map(entry => {
      return {
        ...entry,
      }
    })
    this.initialWorkplaces = data.map(entry => {
      return {
        ...entry,
      }
    })
  }

  filteredFormData(country: string): string[] {
    if (!country) {
      return
    }
    return [...this.workplacesService.getCountyMapping(country).values()]
  }

  getCounties(country: string): SelectOption<string>[] {
    if (!country) {
      return []
    }
    return [...this.workplacesService.getCountyMapping(country).values()].map(county => {
      return {
        displayName: this.getCounty(county, country),
        value: county,
      }
    })

  }

  setCounties(): void {

    this.counties = new Map(
      Array.from(this.workplacesService.getCountyMappingForAll()).map(([key, value]) => {
        return [
          key,
          Array.from(value).map(([key1, value1]) => {
            return {
              displayName: key1,
              value: value1
            }
          })
        ]
      })
    )
    console.log(this.counties)
    console.log(this.counties.get('DE'))
  }

  formatDate(date: string): string {
    return DateUtils.formatDate(DateUtils.stringToDate(date))
  }

  onCountryChange(data: WorkplacesTableDate): void {
    data.county = ''
    this.validateWorkplaces()
  }

  updateWorkplaces(workplaces: WorkplacesTableDate[]): WorkplacesTableDate[] {
    workplaces = workplaces.map(workplace => {
      return {
        ...workplace,
        currentWorkplace: null,
        futureWorkplace: null,
      }
    })
    if (workplaces.length > 1) {
      for (let i = workplaces.length - 1; i >= 0; i--) {
        const date = DateUtils.stringToDate(workplaces[i].startDate)
        const isFirstDateBeforeOrToday = DateUtils.isBeforeDate(date, DateUtils.today) || DateUtils.isToday(date)
        if (isFirstDateBeforeOrToday) {
          workplaces[i].currentWorkplace = true
          break
        }
        workplaces[i].futureWorkplace = true
      }
    } else {
      workplaces[0].currentWorkplace = true
    }
    return workplaces
  }

  countFutureWorkplaces(): boolean {
    return !this.workplacesDialogData?.some(workplace => workplace.futureWorkplace)
  }

  onAfterAddWorkplaceDialogClosed(result: any): void {
    if (result != null) {
      if (result.dialogAction === DialogReturnType.SAVE) {
        const current = this.workplacesDialogData.filter(workplace => workplace.currentWorkplace)
        const date1 = DateUtils.stringToDate(result.workplace.startDate)
        const date2 = DateUtils.stringToDate(current[0].startDate)
        if (DateUtils.isBeforeDate(date1, date2)) {
          const workplace = {
            ...result.workplace,
            futureWorkplace: true,
          } as WorkplacesTableDate
          this.workplacesDialogData.push(workplace)
        } else {
          this.workplacesDialogData.push(result.workplace as WorkplacesTableDate)
          this.workplacesDialogData = this.updateWorkplaces(this.workplacesDialogData)
        }
      }
    }
  }

  delete(data: WorkplacesTableDate): void {
    this.workplacesDialogData = this.workplacesDialogData.filter(workPlace => workPlace !== data)
    this.validateWorkplaces()
  }

  validateWorkplaces(): void {
    this.noWorkplaceSelected = this.workplacesDialogData.some(data => data.county === '' || data.country === '')
  }

  cancel(): void {
    this.dialogRef.close({
      result: DialogReturnType.CANCEL
    })
  }

  send(): void {
    this.validateWorkplaces()

    if (!this.noWorkplaceSelected) {
      this.dialogRef.close({
        dialogAction: DialogReturnType.SAVE,
        userData: this.user,
        workplaceData: this.workplacesDialogData,
        initialWorkPlaces: this.initialWorkplaces,
      })
    }
  }

  anonymizeUser(userEntry: UserEntry): void {
    this.submitMonthService.getNumberOfMonthSubmittedBySubmitTypes(
      [SubmitType.Open, SubmitType.Reopened, SubmitType.Submitted], userEntry.id)
      .then((data) => {
        const anonPossible = data < 1
        const dialog = this.dialog.open(UsersAnonDialogComponent, {
          data: {name: userEntry.name, anonPossible},
          autoFocus: anonPossible,
        })
        dialog.afterClosed().subscribe(result => {
          if (result?.dialogAction === DialogReturnType.SAVE) {
            userEntry.status = UserStatus.ANONYM
            userEntry.timeSheetStatus = TimeSheetStatus.OPTIONAL
            userEntry.timeSheetStatusSort = mapSortStateToNumber(userEntry.timeSheetStatus)
            const foundUser = this.userService.all.find(user => user.id === userEntry.id)
            if (foundUser !== undefined) {
              this.userService.anonymizeUser(foundUser)
            }
            this.dialogRef.close({
              result: DialogReturnType.DELETE
            })
          }
        })
      })
  }

  public isSupervisorEquals(thisSupervisor: User, otherSupervisor: User): boolean {
    return (thisSupervisor != null && otherSupervisor != null) && (thisSupervisor.id === otherSupervisor.id)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private checkChangeContactEmailPermission(): void {
    if (this.type === UsersEditDialogType.BaseData) {
      this.userService.getPermissionsOfUser(this.user.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(permissions => {
          this.canEditEmail = permissions.some(permission => permission === Permission.ChangeContactEmailPermission)
        })
    } else {
      this.canEditEmail = this.user.permissions.some(permission => permission === Permission.ChangeContactEmailPermission)
    }
  }
}

export interface UsersEditDialogData {
  user: UserEntry
  type: UsersEditDialogType
}

export enum UsersEditDialogType {
  Snackbar, BaseData
}
