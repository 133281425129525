import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TopicEntity, TopicService} from '../../services/repository/topic.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TopicEditDialogData} from '../topics-editor.component.ts/topics-editor.component';
import {
  ServerConstantsService,
  TOPICS_DESCRIPTION_MAX_LENGTH,
  TOPICS_NAME_MAX_LENGTH
} from '../../services/server-constants.service';
import {Subscription} from 'rxjs';

const DEFAULT_INPUT_LENGTH = 200;

@Component({
  selector: 'app-topic-edit-dialog',
  templateUrl: './topic-edit-dialog.component.html',
  styleUrls: ['./topic-edit-dialog.component.scss']
})
export class TopicEditDialogComponent implements OnInit, OnDestroy {

  topic: TopicEntity = null;

  topicNameMaxLength: number;
  topicDescriptionMaxLength: number;

  private serverConstantsSubscription: Subscription;

  constructor(private dialogRef: MatDialogRef<TopicEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: TopicEditDialogData,
              private topicService: TopicService,
              private serverConstantsService: ServerConstantsService) {
  }

  ngOnInit(): void {
    if (!this.data.addTopic) {
      this.topicService.get(this.data.id).subscribe(topic => this.topic = topic);
    } else {
      this.topic = {
        name: '',
        description: '',
        assignmentFK: this.data.assignmentId
      };
    }
    this.serverConstantsSubscription = this.serverConstantsService.onConstantsExtracted$.subscribe(constants => {
      this.topicNameMaxLength = constants[TOPICS_NAME_MAX_LENGTH] || DEFAULT_INPUT_LENGTH;
      this.topicDescriptionMaxLength = constants[TOPICS_DESCRIPTION_MAX_LENGTH] || DEFAULT_INPUT_LENGTH;
    });
  }

  ngOnDestroy(): void {
    this.serverConstantsSubscription.unsubscribe();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close();

    if (this.data.addTopic) {
      this.topicService.create(this.topic);
    } else {
      this.topicService.update(this.topic);
    }
  }
}
