<div class="archived-checkbox">
  <mat-checkbox (change)="updateShowArchived()" [checked]="showArchived" class="archived-checkbox">
    Archivierte Daten anzeigen
  </mat-checkbox>
</div>

<div class="editor-view-container">
  <div class="editor-view">
    <app-entity-editor
      (delete)="deleteCustomer($event)"
      (edit)="editCustomer($event)"
      (new)="newCustomer()"
      (restore)="restoreCustomer($event)"
      (selectEntry)="selectCustomer($event)"
      [entries]="customerEntries"
      [highlightEntry]="selectedCustomer"
      [disabled] = false
      label="Kunden"
    ></app-entity-editor>
  </div>

  <div class="editor-view">
    <app-entity-editor
      (delete)="deleteAssignment($event)"
      (edit)="editAssignment($event)"
      (new)="newAssignment()"
      (restore)="restoreAssignment($event)"
      (selectEntry)="selectAssignment($event)"
      [entries]="assignmentEntries"
      [highlightEntry]="selectedAssignment"
      [disabled]="selectedCustomer == null || selectedCustomer.archived"
      [rebookable]="false"
      label="Aufträge"
    ></app-entity-editor>
  </div>

  <div class="editor-view">
    <app-entity-editor
      (delete)="deleteTopic($event)"
      (edit)="editTopic($event)"
      (new)="newTopic()"
      (rebook)="rebookTopic($event)"
      (restore)="restoreTopic($event)"
      [entries]="topicEntries"
      [rebookable]="true"
      [disabled]="selectedAssignment == null || selectedAssignment.archived"
      label="Themen"
    ></app-entity-editor>
  </div>
</div>
