<div class="archived-checkbox">
  <mat-checkbox (change)="updateShowArchived()" [checked]="showArchived" class="archived-checkbox">
    Archivierte Daten anzeigen
  </mat-checkbox>
</div>
<div class="time-types-editor-container">
  <app-time-entry-type-editor [showArchived]="showArchived" class="time-entry-editor"></app-time-entry-type-editor>
  <app-absence-entry-type-editor [showArchived]="showArchived"
                                 class="absence-entry-editor"></app-absence-entry-type-editor>
</div>
