import {Injectable} from '@angular/core';
import {GraphqlCollectorService} from './http/graphql-collector.service';
import {DateRange} from '../util/date-range-picker';
import {UserEntry} from './repository/user.service';
import {TopicData, TopicService} from './repository/topic.service';
import {MergedTopic} from './merged-topics.service';
import {createVariable} from '../util/graphql-executor';
import {DateUtils, TimeValue} from '../util/date-utils';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RebookService {

  constructor(private graphQlService: GraphqlCollectorService) {
  }


  rebookTopic(fromTopicId: number, toTopicId: number, user: UserEntry = null, dateRange: DateRange = null): Observable<void> {
    return this.graphQlService.mutation(this.rebookTopicGraphQlMutation(fromTopicId, toTopicId, user?.id, dateRange));
  }


  private rebookTopicGraphQlMutation(fromTopicId: number, toTopicId: number, userid: number = null, dateRange: DateRange = null): any {

    let from = null;
    let to = null;
    if (userid === undefined) {
      userid = null;
    }
    if (dateRange != undefined) {
      from = DateUtils.dateToString(dateRange.from);
      to = DateUtils.dateToString(dateRange.to);
    }
    return {
      function: 'rebookTopic',
      variables: [
        createVariable('userId', 'Int', userid),
        createVariable('fromTopicId', 'Int!', fromTopicId),
        createVariable('toTopicId', 'Int!', toTopicId),
        createVariable('from', 'StringDate', from),
        createVariable('to', 'StringDate', to)
      ],
      fieldBody: REBOOKTOPICOUTPUT_TEMPLATE
    };
  }


  async getCalculatedTopicData(topicId: number, user: UserEntry = null, dateRange: DateRange = null): Promise<TopicData> {
    return new Promise<TopicData>(resolve => {
      this.graphQlService.query(TopicService.getTimeInfoGraphsQLQuery(topicId, user?.id, dateRange)).subscribe(result => {
        resolve(result);
      });
    });
  }


}


export interface CalculatedTopicData {
  totalTime: number;
  employeeBookedTime: number;
  change: number;
}

export interface CalculatedSelectedTopicData {
  totalTime: TimeValue;
  employeeBookedTime: TimeValue;
  change: TimeValue;
}


export const REBOOKTOPICOUTPUT_TEMPLATE = `
      valueOrError {
        ... on TimeEntryUpsertResult {
          id
        }
        ... on TimeEntryMutationError {
          id
          status
          message
        }
      }
 `;
