import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TimeSheetStatus, toUserEntry, User, UserEntry, UserService} from '../../services/repository/user.service';
import {SortState} from '../../shared-components/sortable-table/sortable-table.component';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {LocalUserPropertiesService} from '../../services/local-user-properties.service';
import {DialogReturnType} from '../../shared-components/dialog-return-type';
import {UsersEditDialogComponent, UsersEditDialogType} from '../users-edit-dialog/users-edit-dialog.component';
import {WorkplacesService} from '../../services/repository/workplaces.service';

const SHOW_ANONYMIZED_USERS_KEY = 'showAnonymizedUsers';

@Component({
  selector: 'app-users-editor',
  templateUrl: './users-editor.component.html',
  styleUrls: ['./users-editor.component.scss']
})
export class UsersEditorComponent implements OnInit, OnDestroy, OnChanges {
  userEntries: UserEntry[];

  statusDisplayNames = new Map<TimeSheetStatus, string>([
    [TimeSheetStatus.REQUIRED, 'Erforderlich'],
    [TimeSheetStatus.OPTIONAL, 'Optional']
  ]);

  asc = SortState.ASCENDING;
  columns = [
    {displayName: 'Mitarbeiter', fieldName: 'name', sortable: true, initialSort: true},
    {displayName: 'Dienstleister', fieldName: 'companyName', sortable: true},
    {displayName: 'KontaktEmail', fieldName: 'email', sortable: true},
    {displayName: 'Start', fieldName: 'workStart', sortable: true},
    {displayName: 'Nachweis', fieldName: 'timeSheetStatusSort', sortable: true},
    {displayName: 'Verantwortlicher', fieldName: 'supervisorName', sortable: true},
    {displayName: 'Bearbeiten', sortable: false}

  ];

  filter = '';
  inputControl = new UntypedFormControl();
  showAnonymizedUsers: boolean;
  tableData: UserEntry[];

  constructor(private userService: UserService,
              private dialog: MatDialog,
              private localUserPropertiesService: LocalUserPropertiesService,
              private workplacesService: WorkplacesService) {
  }

  async ngOnInit(): Promise<void> {
    this.showAnonymizedUsers = await this.localUserPropertiesService.getProperty(SHOW_ANONYMIZED_USERS_KEY, false);

    this.userService.all$.subscribe(users => {
      if (users !== undefined) {
        this.userEntries = users.map(toUserEntry);
        this.updateUsersData();
      }
    });

    this.inputControl.valueChanges.subscribe(value => {
      this.filter = value;
      this.updateUsersData();
    });

    this.userService.requestAll().then();

    this.userService.requestSupervisors().then();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateUsersData();
  }

  ngOnDestroy(): void {
    this.localUserPropertiesService.setProperty(SHOW_ANONYMIZED_USERS_KEY, this.showAnonymizedUsers).then();
  }

  filterFunction(userEntry: UserEntry, filter: string): boolean {
    const filterStrings = filter.split(' ');
    return filterStrings.reduce((isValid, filterString) => {
      return isValid && userEntry.name.toLowerCase().includes(filterString.toLowerCase());
    }, true);
  }

  isTimeSheetRequired(userEntry: UserEntry): boolean {
    return userEntry.timeSheetStatus === TimeSheetStatus.REQUIRED;
  }

  isTimeSheetOptional(userEntry: UserEntry): boolean {
    return userEntry.timeSheetStatus === TimeSheetStatus.OPTIONAL;
  }

  editUser(userEntry: UserEntry): void {
    const dialog = this.dialog.open(UsersEditDialogComponent, {
      data: {user: userEntry, type: UsersEditDialogType.BaseData}
    });
    dialog.afterClosed().subscribe(result => {
      if (result?.dialogAction === DialogReturnType.SAVE) {
        const resultUser = result.userData as User;
        const oldSupervisorId = (userEntry.supervisor) ? userEntry.supervisor.id : null;
        const newSupervisorId = (resultUser.supervisor) ? resultUser.supervisor.id : null;
        if (oldSupervisorId !== newSupervisorId
          || userEntry.timeSheetStatus !== resultUser.timeSheetStatus
          || userEntry.email !== resultUser.email) {
          this.userService.editUser(result.userData as User);
        }

        const newWorkplaces = result.workplaceData.filter(workplace => workplace.id == null);
        if (newWorkplaces.length > 0) {
          newWorkplaces.forEach(workplace =>
            this.workplacesService.addWorkplaces(this.workplacesService.workplacesTableDataToWorkplaces(workplace))
          );
        }

        const deletedWorkplaces = result.initialWorkPlaces.filter(
          initialWorkplace => result.workplaceData.some(workplace => initialWorkplace.id === workplace.id) === false
        );
        if (deletedWorkplaces.length > 0) {
          deletedWorkplaces.forEach(workplace =>
            this.workplacesService.deleteWorkplaces(workplace.id)
          );
        }

        const changedWorkplaces = result.workplaceData.filter(workplace => workplace.id != null)
          .filter(
            workplace => result.initialWorkPlaces.some(initialWorkplace =>
              initialWorkplace.id === workplace.id &&
              initialWorkplace.county === workplace.county &&
              initialWorkplace.country === workplace.country &&
              initialWorkplace.startDate === workplace.startDate) === false
          );
        if (changedWorkplaces.length > 0) {
          changedWorkplaces.forEach(workplace => {
              this.workplacesService.updateWorkplaces(this.workplacesService.workplacesTableDataToWorkplaces(workplace));
            }
          );

        }
      }
    });
  }

  updateUsersData(): void {
    this.tableData =
      (this.showAnonymizedUsers || this.userEntries === undefined) ?
        this.userEntries :
        this.userEntries.filter(userEntry => !this.userService.isAnonym(userEntry));
  }
}



