import {Injectable} from '@angular/core'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {ErrorDialogComponent, ErrorDialogData} from '../shared-components/error-dialog/error-dialog.component'
import {HttpClient} from '@angular/common/http'
import {DateUtils} from '../util/date-utils'
import {environment} from 'src/environments/environment'


export interface ErrorMessage {
  timestamp: string,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private dialogOpen = false
  private errorMessages: ErrorMessage[] = []
  private dialogRef: MatDialogRef<ErrorDialogComponent>
  private repoURL: string

  constructor(private dialog: MatDialog,
              private httpClient: HttpClient) {
    this.repoURL = `${environment.serverUrl}/test`
  }

  getError() {
    this.httpClient.get(`${this.repoURL}/error`).subscribe()

    // setTimeout(() => {this.httpClient.get(`${REPO_URL}/error`).subscribe()}, 1000);
  }

  getOK() {
    this.httpClient.get(`${this.repoURL}/ok`).subscribe()
  }

  postError() {
    this.httpClient.post(`${this.repoURL}/401`, {data: {hallo: 'welt', welt: 'hallo'}}).subscribe()
  }

  showErrorDialog(errorMessage: string): void {
    this.errorMessages.push({
      timestamp: DateUtils.timeStamp(),
      message: errorMessage
    })
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.data = {errorMessages: this.errorMessages} as ErrorDialogData
    } else if (!this.dialogOpen) {
      this.dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: {
          errorMessages: this.errorMessages
        } as ErrorDialogData,
      })
      this.dialogRef.afterOpened().subscribe(() => {
        this.dialogOpen = true
      })
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogOpen = false
        this.errorMessages = []

        window.location.reload()
      })
    }
  }
}
