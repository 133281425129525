<div *ngIf="topic != null">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="topic.name" [maxlength]="topicNameMaxLength" matInput placeholder="Name">
      <mat-hint align="end">{{topic.name.length}}/{{topicNameMaxLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input [(ngModel)]="topic.description" [maxlength]="topicDescriptionMaxLength" matInput
             placeholder="Beschreibung">
      <mat-hint align="end">{{topic.description.length}}/{{topicDescriptionMaxLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>OK</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>
