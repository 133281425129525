import {BasedataEntry} from '../basedata/entity-editor/entity-editor.component'
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  ConfirmDialogType
} from '../shared-components/confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog';
import {Observable, ReplaySubject} from 'rxjs'


export class BaseDataEntryService {

  static sortEntries(entries: BasedataEntry[]) {
    return entries.sort((a, b) => {
      if (a.archived == true && b.archived == false) {
        return 1
      }
      if (a.archived == false && b.archived == true) {
        return -1
      }
      if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) {
        return -1
      }
      return 1
    })
  }

  static openArchiveDialog(dialog: MatDialog, entry: BasedataEntry): Observable<boolean> {
    const shutdown: ReplaySubject<boolean> = new ReplaySubject()

    const dialogRef = dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        text: 'Wollen Sie den Typ ' + entry.label + ' wirklich archivieren?',
        type: ConfirmDialogType.INFO
      } as ConfirmDialogData
    })
    dialogRef.afterClosed().toPromise().then(returnCode =>
      shutdown.next(returnCode)
    )
    return shutdown
  }
}
