<div class="header row">
  <div class="horizontal-layout">
    <div class="label">{{label}}</div>
    <div class="actions">

      <button (click)="new.emit()" mat-mini-fab title="Hinzufügen" disabled="{{disabled}}" >
        <mat-icon>add</mat-icon>
      </button>

    </div>
  </div>
</div>

<div cdkDropList [class.cdk-drop-list-disabled]="!itemsDraggable" (cdkDropListDropped)="dropAbsence($event)"
     class="drop-list-container">
  <div *ngFor="let entry of entries">
    <div (click)="selectEntry.emit(entry)" *ngIf="entry.archived == false"
         [class.highlighted]="highlightEntry != null && entry.id == highlightEntry.id"
         class="row selectable drag-item-container" cdkDrag [cdkDragDisabled]="!itemsDraggable">
      <div class="horizontal-layout">
        <div cdkDragHandle class="drag-indicator-container">
          <mat-icon *ngIf="itemsDraggable" class="material-icons" color="primary"
                    [title]="itemsDraggable ? 'Zeile verschieben' : ''">
            drag_indicator
          </mat-icon>
        </div>
        <div class="label">{{entry.label}}</div>
        <div class="actions">

          <div>
            <button  (click)="rebook.emit(entry)" mat-mini-fab title="Umbuchen" *ngIf="rebookable">
              <mat-icon>code</mat-icon>
            </button>
            <button (click)="edit.emit(entry)" mat-mini-fab title="Bearbeiten">
              <mat-icon>edit</mat-icon>
            </button>
            <button (click)="delete.emit(entry)" mat-mini-fab title="Archivieren">
              <mat-icon>archive</mat-icon>
            </button>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="entry.archived" class="row archived">
      <div class="horizontal-layout">
        <div class="drag-indicator-container">
          <mat-icon *ngIf="itemsDraggable" class="material-icons" color="grey"
                    [title]="itemsDraggable ? 'Zeile verschieben' : ''">
            drag_indicator
          </mat-icon>
        </div>
        <div class="label">{{entry.label}}</div>
        <div class="actions">
          <button [disabled]="disabled" (click)="restore.emit(entry)" mat-mini-fab title="Wiederherstellen">
            <mat-icon>restore</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
