import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {TimeEntryTypeEntity, TimeEntryTypeService, TypeNames, Types} from '../../services/repository/time-entry-type.service'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {TimeEntryTypeDialogData} from '../time-entry-type-editor/time-entry-type-editor.component'
import {
  ServerConstantsService,
  TIME_ENTRY_TYPES_DESCRIPTION_MAX_LENGTH,
  TIME_ENTRY_TYPES_NAME_MAX_LENGTH
} from '../../services/server-constants.service'
import {Subscription} from 'rxjs'
import {SelectOption} from '../../shared-components/select/select.component'

const DEFAULT_INPUT_LENGTH = 200

const PASSIVE_TIME_POSTFIX = '(passiv)'

@Component({
  selector: 'app-time-entry-type-dialog',
  templateUrl: './time-entry-type-dialog.component.html',
  styleUrls: ['./time-entry-type-dialog.component.scss']
})
export class TimeEntryTypeDialogComponent implements OnInit, OnDestroy {

  timeEntryType: TimeEntryTypeEntity = null
  options: SelectOption<Types>[] = Object.keys(Types).map(type => {
    return {
      displayName: TypeNames[type],
      value: Types[type]
    }
  })
  names = TypeNames

  nameInputLength: number = DEFAULT_INPUT_LENGTH
  descriptionInputLength: number = DEFAULT_INPUT_LENGTH

  private serverConstantsSubscription: Subscription
  private timeEntryTypesSubscription: Subscription

  constructor(private dialogRef: MatDialogRef<TimeEntryTypeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: TimeEntryTypeDialogData,
              private timeEntryTypeService: TimeEntryTypeService,
              private serverConstantsService: ServerConstantsService) {
  }


  ngOnInit(): void {
    this.serverConstantsSubscription = this.serverConstantsService.onConstantsExtracted$.subscribe((constants) => {
      this.nameInputLength = constants[TIME_ENTRY_TYPES_NAME_MAX_LENGTH] || this.nameInputLength
      this.descriptionInputLength = constants[TIME_ENTRY_TYPES_DESCRIPTION_MAX_LENGTH] || this.descriptionInputLength
    })

    if (!this.data.addTimeEntryType) {
      this.timeEntryTypesSubscription = this.timeEntryTypeService.all$.subscribe(() => {
        const timeEntryType = this.timeEntryTypeService.get(this.data.id)
        this.timeEntryType = (timeEntryType != null) ? {...timeEntryType} : null
      })
    } else {
      this.timeEntryType = {
        id: -1,
        name: '',
        description: '',
        type: undefined,
        ratio: 0,
        archived: false
      }
    }

    if (this.timeEntryType?.type === Types.specialPassive) {
      this.timeEntryType.name = this.timeEntryType.name.split(PASSIVE_TIME_POSTFIX)[0].trim()
    }
  }

  ngOnDestroy(): void {
    this.serverConstantsSubscription.unsubscribe()

    if (this.timeEntryTypesSubscription !== undefined) {
      this.timeEntryTypesSubscription.unsubscribe()
    }
  }

  cancel(): void {
    this.dialogRef.close()
  }

  confirm(): void {
    this.dialogRef.close()
    const timeEntryTypeIncludesPostfix = this.timeEntryType.name.toLowerCase().includes(PASSIVE_TIME_POSTFIX)
    if (this.timeEntryType.type === Types.specialPassive) {
      if (!timeEntryTypeIncludesPostfix) {
        this.timeEntryType.name += ' ' + PASSIVE_TIME_POSTFIX
      }
    } else if (timeEntryTypeIncludesPostfix) {
      this.timeEntryType.name = this.timeEntryType.name.replace(PASSIVE_TIME_POSTFIX, '').trim()
    }

    if (this.data.addTimeEntryType) {
      this.timeEntryTypeService.create(this.timeEntryType)
    } else {
      this.timeEntryTypeService.update(this.timeEntryType)
    }
  }
}
