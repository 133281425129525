import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'

@Component({
  selector: 'app-entity-editor',
  templateUrl: './entity-editor.component.html',
  styleUrls: ['./entity-editor.component.scss'],
})
export class EntityEditorComponent implements OnInit {

  @Input() entries: BasedataEntry[] = []
  @Input() label = ''
  @Input() highlightEntry: BasedataEntry = null
  @Input() disabled = false
  @Input() rebookable = false

  @Output() edit = new EventEmitter<BasedataEntry>()
  @Output() rebook = new EventEmitter<BasedataEntry>()
  @Output() new = new EventEmitter<void>()
  @Output() selectEntry = new EventEmitter<BasedataEntry>()
  @Output() delete = new EventEmitter<BasedataEntry>()
  @Output() restore = new EventEmitter<BasedataEntry>()
  @Output() dropEntry = new EventEmitter<void>()

  @Input() itemsDraggable = false

  constructor() {
  }

  ngOnInit(): void {
  }

  dropAbsence(event: CdkDragDrop<BasedataEntry[]>): void {
    moveItemInArray(this.entries, event.previousIndex, event.currentIndex)
    this.dropEntry.emit()
  }

}

export interface BasedataEntry {
  id: number
  label: string
  archived: boolean
}
