import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogReturnType} from '../../shared-components/dialog-return-type';

@Component({
  selector: 'app-users-anon-dialog',
  templateUrl: './users-anon-dialog.component.html',
  styleUrls: ['./users-anon-dialog.component.scss']
})
export class UsersAnonDialogComponent implements OnInit {

  captcha: string;

  constructor(private dialogRef: MatDialogRef<UsersAnonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UsersAnonDialogData) {
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({top: '20vh'});
  }

  cancel(): void {
    this.dialogRef.close({dialogAction: DialogReturnType.CANCEL});
  }

  send(): void {
    if (this.isValidCaptcha()) {
      this.dialogRef.close({dialogAction: DialogReturnType.SAVE});
    }
  }

  isValidCaptcha(): boolean {
    const validCaptchaResult = this.data.name + ' anonymisieren';
    return this.captcha === validCaptchaResult;
  }

}

export interface UsersAnonDialogData {
  name: string;
  anonPossible: boolean;
}
