<app-entity-editor
  (delete)="archiveAbsenceEntryType($event)"
  (edit)="editAbsenceEntryType($event)"
  (new)="newAbsenceEntryType()"
  (restore)="restoreAbsenceEntryType($event)"
  (selectEntry)="selectAbsenceEntryType($event)"
  (dropEntry)="updateAbsenceEntryTypes()"
  [entries]="absenceEntryTypes"
  [highlightEntry]="selectedAbsenceEntryType"
  [itemsDraggable]="true"
  label="Abwesenheits-Typen"
></app-entity-editor>


