import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {AbsenceEntryTypeEntity, AbsenceEntryTypeService, Types, TypeNames} from '../../services/repository/absence-entry-type.service'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {AbsenceEntryTypeDialogData} from '../absence-entry-type-editor/absence-entry-type-editor.component'
import {Subscription} from 'rxjs'
import {
  ABSENCE_TYPES_DESCRIPTION_MAX_LENGTH,
  ABSENCE_TYPES_NAME_MAX_LENGTH,
  ServerConstantsService
} from '../../services/server-constants.service'
import {SelectOption} from '../../shared-components/select/select.component'

const DEFAULT_INPUT_LENGTH = 200

@Component({
  selector: 'app-absence-entry-type-editor-dialog',
  templateUrl: './absence-entry-type-editor-dialog.component.html',
  styleUrls: ['./absence-entry-type-editor-dialog.component.scss']
})
export class AbsenceEntryTypeEditorDialogComponent implements OnInit, OnDestroy {

  absenceEntryType: AbsenceEntryTypeEntity = null

  options: SelectOption<Types>[] = Object.keys(Types).map(type => {
    return {
      displayName: TypeNames[type],
      value: Types[type]
    }
  })

  nameInputLength: number = DEFAULT_INPUT_LENGTH
  descriptionInputLength: number = DEFAULT_INPUT_LENGTH

  private serverConstantsSubscription: Subscription
  private absenceEntryTypesSubscription: Subscription

  constructor(private dialogRef: MatDialogRef<AbsenceEntryTypeEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: AbsenceEntryTypeDialogData,
              private absenceEntryTypeService: AbsenceEntryTypeService,
              private serverConstantsService: ServerConstantsService) {
  }


  ngOnInit(): void {
    if (!this.data.addTimeEntryType) {
      this.absenceEntryTypesSubscription = this.absenceEntryTypeService.all$.subscribe(() => {
        const absenceEntryType = this.absenceEntryTypeService.getById(this.data.id)
        this.absenceEntryType = (absenceEntryType != null) ? {...absenceEntryType} : null
      })
    } else {
      this.absenceEntryType = {
        id: -1,
        name: '',
        description: '',
        ratio: 0,
        archived: false,
        kind: null,
        holiday: false
      }
    }
    this.serverConstantsSubscription = this.serverConstantsService.onConstantsExtracted$.subscribe(constants => {
      this.nameInputLength = constants[ABSENCE_TYPES_NAME_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
      this.descriptionInputLength = constants[ABSENCE_TYPES_DESCRIPTION_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
    })
  }

  ngOnDestroy(): void {
    this.serverConstantsSubscription.unsubscribe()
    this.absenceEntryTypesSubscription?.unsubscribe()
  }

  cancel(): void {
    this.dialogRef.close()
  }

  confirm(): void {
    this.dialogRef.close()

    if (this.data.addTimeEntryType) {
      this.absenceEntryTypeService.create(this.absenceEntryType)
    } else {
      this.absenceEntryTypeService.update(this.absenceEntryType)
    }
  }
}
