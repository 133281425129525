import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {TopicData, TopicEntity, TopicService} from '../../services/repository/topic.service'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {TopicEditDialogData} from '../topics-editor.component.ts/topics-editor.component'
import {Observable, Subscription} from 'rxjs'
import {UntypedFormControl} from '@angular/forms'
import {map, startWith} from 'rxjs/operators'
import {AssignmentEntity} from '../../services/repository/assignment.service'
import {UserEntry, UserService} from '../../services/repository/user.service'
import {SmartInputOption, SmartInputOptionService} from '../../services/smart-input-option.service'
import {SelectedDateRange} from '../../shared-components/daterange-picker/daterange-picker.component'
import {MergedTopic, MergedTopicsService} from '../../services/merged-topics.service'
import {Async} from '../../util/async'
import {ErrorService} from '../../services/error.service'
import {DialogReturnType} from '../../shared-components/dialog-return-type'
import {RebookTopicConfirmDialogComponent} from './rebook.confirm-dialog/topic-rebook-confirm-dialog.component'
import {CalculatedSelectedTopicData, CalculatedTopicData, RebookService} from '../../services/rebook-service'
import {DateUtils, TimeValue} from '../../util/date-utils'
import {SynchronisationService} from '../../services/sync/synchronize-data.service'
import {ImageService} from '../../services/repository/image.service'
import {DateRange} from '../../util/date-range-picker'

// TODO feedback nach dem Umbuchen einbauen


@Component({
  selector: 'app-topic-rebook-dialog',
  templateUrl: './topic-rebook-dialog.component.html',
  styleUrls: ['./topic-rebook-dialog.component.scss']
})
export class RebookTopicDialogComponent implements OnInit, OnDestroy {

  dateRange: SelectedDateRange = null
  selectedUser: UserEntry = null
  currentTopicIcon = ''
  currentTopicDataLoading = true
  currentTopic: MergedTopic = null
  userOnTopic: number[] = []
  userSelectedTopic: SmartInputOption = null
  userSelectedTopicDataLoading = true
  calculatedcurrentTopicData: CalculatedTopicData = {totalTime: null, change: null, employeeBookedTime: null}
  calculatedUserSelectedTopicData: CalculatedSelectedTopicData
  private topicAutocompleteControl = new UntypedFormControl()
  private topicOptions: TopicOptionEntity[] = []
  private filteredTopicOptions: Observable<TopicOptionEntity[]>
  private mergedTopicServiceSubscription: Subscription
  private basedataCacheReady = false

  constructor(private dialogRef: MatDialogRef<RebookTopicDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: TopicEditDialogData,
              private topicService: TopicService,
              private mergedTopicsService: MergedTopicsService,
              private smartInputOptionService: SmartInputOptionService,
              private errorService: ErrorService,
              private rebookService: RebookService,
              private syncService: SynchronisationService,
              private imageService: ImageService,
              private dialog: MatDialog,
              private userService: UserService) {
    this.mergedTopicServiceSubscription = mergedTopicsService.mergedBasedata$.subscribe(mergedBasedata => {
      this.basedataCacheReady = mergedBasedata != null
    })
  }

  ngOnInit(): void {
    if (this.data.id != null) {
      this.requestTopic(this.data.id).then(topic => {
        this.currentTopic = topic
        this.refreshDataFromServerAndRecalculate()
        this.imageService.getDecodedImage(topic.customer.icon).then(icon => {
          this.currentTopicIcon = icon
        })
      })
    } else {
      this.errorService.showErrorDialog(`Leider konnten wir kein Thema mit der id: ${this.data.id} finden. `)
    }


    this.filteredTopicOptions = this.topicAutocompleteControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTopics(value))
    )

  }

  async requestTopic(id): Promise<MergedTopic> {
    await Async.sleepUntil(() => this.basedataCacheReady)
    return this.mergedTopicsService.mergedBasedata.find(el => {
      return el.topic.id === id
    })
  }

  ngOnDestroy(): void {
    this.mergedTopicServiceSubscription.unsubscribe()
  }

  cancel(): void {
    this.dialogRef.close()
  }

  confirm(): void {
    const dialog = this.dialog.open(RebookTopicConfirmDialogComponent, {
      width: '560px',
      data: {
        mergedTopic: this.currentTopic,
        selectedUser: this.selectedUser,
        selectedTopic: this.userSelectedTopic
      },
    })

    dialog.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.dialogAction === DialogReturnType.CONFIRM) {
          this.dialogRef.close()
          this.rebookService.rebookTopic(this.currentTopic.topic.id, this.userSelectedTopic.topicId, this.selectedUser, this.dateRange)
            .subscribe(done => {
              this.syncService.forceSynchronize().then()
            })
        }
      }
    })
  }

  onUserSelected(value): void {
    if (this.isUserEntry(value)) {
      this.selectedUser = value
    } else {
      this.selectedUser = null
    }
    this.refreshDataFromServerAndRecalculate()
  }

  onTopicSelected(selectedTopic: SmartInputOption): void {
    this.userSelectedTopic = selectedTopic
    this.refreshDataFromServerAndRecalculate()
  }

  onDateRangeSelect(value): void {
    this.dateRange = value
    this.refreshDataFromServerAndRecalculate()
  }

  getDateRange(): DateRange | null {
    return this.dateRange != null && this.dateRange.from != null && this.dateRange.to != null ? this.dateRange : null
  }

  getCalculateDataForCurrentTopic(): void {
    if (this.currentTopic != null) {
      this.currentTopicDataLoading = true
      this.rebookService.getCalculatedTopicData(this.currentTopic.topic.id, this.selectedUser, this.getDateRange())
        .then(result => {
          this.calculatedcurrentTopicData.totalTime = result.timeInfo.booked
          this.calculatedcurrentTopicData.change = result.timeInfo.booked - result.timeInfo.bookedByUser
          this.calculatedcurrentTopicData.employeeBookedTime = result.timeInfo.bookedByUser
          this.userService.requestAll().then(users => {
            this.userOnTopic = users?.filter((user) => result.timeInfo.users.indexOf(user.id) >= 0 && !this.userService.isAnonym(user))
              .map(user => user.id) ?? []
          })
          requestAnimationFrame(() => {
            this.currentTopicDataLoading = false
          })
        })
    }
  }

  getCalculatedDataForSelectedTopic(): void {
    this.userSelectedTopicDataLoading = true
    this.rebookService.getCalculatedTopicData(this.userSelectedTopic.topicId, this.selectedUser, this.getDateRange())
      .then(selectedTopicData => {
        this.userSelectedTopicDataLoading = false
        this.calculatedUserSelectedTopicData = {
          totalTime: DateUtils.secondsToTimeValue(selectedTopicData.timeInfo.booked),
          employeeBookedTime: DateUtils.secondsToTimeValue(selectedTopicData.timeInfo.bookedByUser),
          change: this.calculateChangeForUserSelectedTopic(selectedTopicData),
        }
      })
  }

  isTopicOptionEntity(value): value is SmartInputOption {
    return (value as SmartInputOption).subLabel !== undefined
  }

  resetSelecteddTopic(): void {
    this.userSelectedTopic = null
  }

  isUserEntry(value): value is UserEntry {
    return value && (value as UserEntry).name !== undefined
  }

  private _filterTopics(value): TopicOptionEntity[] {
    let filterValue = ''
    if (this.isTopicOptionEntity(value)) {
      filterValue = value.subLabel.toLowerCase()
    } else {
      const splitted = value.split(':')
      filterValue = splitted.length > 1 ? splitted[1].trim().toLowerCase() : value.toLowerCase()
    }
    return this.topicOptions.filter(option => option.topic.name.toLowerCase().includes(filterValue))
  }

  private refreshDataFromServerAndRecalculate(): void {
    this.getCalculateDataForCurrentTopic()
    if (this.userSelectedTopic != null) {
      this.getCalculatedDataForSelectedTopic()
    }
  }

  private calculateChangeForUserSelectedTopic(selectedTopicData: TopicData): TimeValue {
    return DateUtils.addTimeValues(DateUtils.secondsToTimeValue(selectedTopicData.timeInfo.booked),
      DateUtils.secondsToTimeValue(this.calculatedcurrentTopicData.employeeBookedTime))
  }

  protected formatToTimeValue(seconds: number): string {
    return DateUtils.formatTimeValue(DateUtils.secondsToTimeValue(seconds), false)
  }

  protected fromatTimeValue(timeValue: TimeValue): string {
    return DateUtils.formatTimeValue(timeValue, false)
  }
}


interface TopicOptionEntity {
  topic: TopicEntity
  assignment: AssignmentEntity
}



