import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {CustomerEntity, CustomerService} from '../../services/repository/customer.service'
import {EditDialogData} from '../topics-editor.component.ts/topics-editor.component'
import {CUSTOMERS_DESCRIPTION_MAX_LENGTH, CUSTOMERS_NAME_MAX_LENGTH, ServerConstantsService} from '../../services/server-constants.service'
import {Subscription} from 'rxjs'
import {ErrorTooltipComponent} from '../error-tooltip/error-tooltip.component'
import {ImageService} from '../../services/repository/image.service'

const DEFAULT_INPUT_LENGTH = 200
const MAX_IMAGE_SIZE = 64 * 1024

@Component({
  selector: 'app-customer-edit-dialog',
  templateUrl: './customer-edit-dialog.component.html',
  styleUrls: ['./customer-edit-dialog.component.scss']
})


export class CustomerEditDialogComponent implements OnInit, OnDestroy {

  customer: CustomerEntity = null

  nameInputLength: number = DEFAULT_INPUT_LENGTH
  descriptionInputLength: number = DEFAULT_INPUT_LENGTH
  imageText = ''
  image: any = ''
  imageArrayBuffer: Uint8Array = undefined
  oldImage: string = undefined
  dialogComponent = ErrorTooltipComponent
  fileSizeError: number = undefined
  private serverConstantsSubscription: Subscription

  constructor(private dialogRef: MatDialogRef<CustomerEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: EditDialogData,
              private customerService: CustomerService,
              private serverConstantsService: ServerConstantsService,
              private imageService: ImageService,
              private dialog: MatDialog) {
  }


  ngOnInit(): void {
    this.serverConstantsSubscription = this.serverConstantsService.onConstantsExtracted$.subscribe((constants) => {
      this.nameInputLength = constants[CUSTOMERS_NAME_MAX_LENGTH] || this.nameInputLength
      this.descriptionInputLength = constants[CUSTOMERS_DESCRIPTION_MAX_LENGTH] || this.descriptionInputLength
    })
    if (!this.data.addCustomer) {
      this.customerService.get(this.data.id).subscribe(customer => {
        this.customer = customer
        this.getCustomerImage()
      })
    } else {
      this.customer = {
        name: '',
        icon: undefined,
        description: ''
      }
    }
  }

  onSelectImage(): void {
    this.fileSizeError = undefined
  }

  async onImageSelected(): Promise<void> {
    const inputNode: any = document.querySelector('#file')

    if (inputNode.files.length > 0) {
      const imageFile: File = inputNode.files[0]
      if (imageFile.size > MAX_IMAGE_SIZE) {
        this.fileSizeError = imageFile.size
        return
      }

      this.imageText = this.getImageName(imageFile)
      const decodedFile = await this.imageService.decodeImageFile(imageFile)

      this.imageArrayBuffer = decodedFile.arrayBuffer
      this.image = decodedFile.sanitziedUrl
      this.customer.icon = this.imageText
    }
  }

  ngOnDestroy(): void {
    this.serverConstantsSubscription.unsubscribe()
  }

  cancel(): void {
    this.dialogRef.close()
  }

  confirm(): void {
    this.dialogRef.close()
    this.imageService
      .uploadImage(this.customer.icon, this.imageArrayBuffer)
      .then((imageEntry) => {
        this.customer.icon = imageEntry.imageName
        this.upsertCustomer()
      }, () => {
        this.upsertCustomer()
      })
  }

  upsertCustomer(): void {
    if (this.data.addCustomer) {
      this.customerService.create(this.customer)
    } else {
      this.customerService.update(this.customer)
    }
  }

  getErrorMessage(): string {
    return 'Die Dateigröße darf maximal 64kB betragen!'
  }

  private getCustomerImage(): void {
    if (this.customer.icon) {
      this.imageService.getImage(this.customer.icon).then(image => {
        if (image) {
          this.image = this.imageService.decodeImageArrayBuffer(image.image)
          this.imageText = image.imageName
        }
      })
    }
  }

  private getImageName(imageFile: any): string {
    return imageFile.name.split('.')[0]
  }
}


