import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasedataEntry} from '../entity-editor/entity-editor.component';
import {TimeEntryTypeService} from '../../services/repository/time-entry-type.service';
import {from, Subscription} from 'rxjs';
import {filter, map, toArray} from 'rxjs/operators';
import {TimeEntryTypeDialogComponent} from '../time-entry-type-dialog/time-entry-type-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BaseDataEntryService} from '../../services/basedata-entry.service';


@Component({
  selector: 'app-time-entry-type-editor',
  templateUrl: './time-entry-type-editor.component.html',
  styleUrls: ['./time-entry-type-editor.component.scss']
})
export class TimeEntryTypeEditorComponent implements OnInit, OnDestroy {

  @Input() showArchived = false;

  timeEntryTypes: BasedataEntry[] = [];
  selectedTimeEntryType: BasedataEntry;
  private timeEntryServiceSubscription: Subscription;


  constructor(private timeEntryTypeService: TimeEntryTypeService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initTimeEntryTypeObservable();
  }

  ngOnDestroy(): void {
    this.timeEntryServiceSubscription.unsubscribe();
  }

  newTimeEntryType(): void {
    this.dialog.open(TimeEntryTypeDialogComponent, {
      width: '500px',
      data: {id: -1, addTimeEntryType: true}
    });
  }

  archiveTimeEntryType(entry: BasedataEntry): void {
    BaseDataEntryService.openArchiveDialog(this.dialog, entry).subscribe(shouldArchive => {
      if (shouldArchive) {
        this.timeEntryTypeService.delete(this.timeEntryTypeService.get(entry.id));
      }
    });
  }

  editTimeEntryType(entry: BasedataEntry): void {
    this.selectedTimeEntryType = entry;

    this.dialog.open(TimeEntryTypeDialogComponent, {
      width: '500px',
      data: {id: this.selectedTimeEntryType.id, addTimeEntryType: false}
    });
  }

  selectTimeEntryType(entry: BasedataEntry): void {
    this.selectedTimeEntryType = entry;
  }

  restoreTimeEntryType(entry: BasedataEntry): void {
    this.timeEntryTypeService.restore(this.timeEntryTypeService.get(entry.id));
  }

  private initTimeEntryTypeObservable(): void {
    this.timeEntryServiceSubscription = this.timeEntryTypeService.all$.subscribe(list => {
      from(list).pipe(
        filter(timeEntryType => (this.showArchived) || (!timeEntryType.archived)),
        map(timeEntryType => {
          return {
            id: timeEntryType.id,
            label: timeEntryType.name,
            archived: timeEntryType.archived
          } as BasedataEntry;
        }),
        toArray()
      ).subscribe(entries => this.timeEntryTypes = BaseDataEntryService.sortEntries(entries));
    });
    this.timeEntryTypeService.requestAll();
  }
}


export interface TimeEntryTypeDialogData {
  id: number;
  addTimeEntryType: boolean;
}
