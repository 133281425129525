<app-entity-editor
  (delete)="archiveTimeEntryType($event)"
  (edit)="editTimeEntryType($event)"
  (new)="newTimeEntryType()"
  (restore)="restoreTimeEntryType($event)"
  (selectEntry)="selectTimeEntryType($event)"
  [entries]="timeEntryTypes"
  [highlightEntry]="selectedTimeEntryType"
  label="Zeiteintrag-Typen"
></app-entity-editor>


