<div class="flex-message-box">
  <div class="flex-message-box-title">Mitarbeiter anonymisieren</div>
  <div class="flex-message-box-content">
    <div class="icon-container">
      <mat-icon class="material-icons top-line">error_outlined</mat-icon>
    </div>
    <ng-container [ngTemplateOutlet]="(data.anonPossible) ? anonPossible : anonNotPossible"></ng-container>
  </div>
  <div class="flex-message-box-actions">
    <button (click)="cancel()" class="cancel-button" mat-button>Abbrechen</button>
    <button (click)="send()" [disabled]="!isValidCaptcha()" color="primary" mat-flat-button>Anonymisieren</button>
  </div>
</div>

<ng-template #anonPossible>
  <div class="message-text">
    <p>
      Beim Anonymisieren von „<span class="text-color-attention">{{data.name}}</span>“ wird der Name des Mitarbeiters
      unkenntlich gemacht.
    </p>
    <p>
      <strong>Dieser Vorgang kann nicht rückgängig gemacht werden!</strong>
    </p>
    <p class="message_demand">
      Tippe „<span class="text-color-attention no-select">{{data.name}} anonymisieren</span>“ ein, um fortzufahren:
    </p>
    <input [(ngModel)]="captcha" class="complete-length" matInput>
  </div>
</ng-template>

<ng-template #anonNotPossible>
  <div class="message-text">
    <p>
      „<span class="text-color-attention">{{data.name}}</span>“ hat noch offene Tätigkeitsnachweise und kann daher nicht
      anonymisiert werden.
    </p>
  </div>
</ng-template>
