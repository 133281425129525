import {Pipe, PipeTransform} from '@angular/core'
import {TimeSheetStatus, User} from '../services/repository/user.service'
import {DateUtils} from '../util/date-utils'
import {UsersEditDialogType} from './users-edit-dialog/users-edit-dialog.component'

const statusDisplayNames = new Map<TimeSheetStatus, string>([
  [TimeSheetStatus.REQUIRED, 'Erforderlich'],
  [TimeSheetStatus.OPTIONAL, 'Optional']
])

@Pipe({
  name: 'statusDisplayName'
})
export class StatusDisplayNamePipe implements PipeTransform {
  transform(internalStatus: TimeSheetStatus): string {
    return statusDisplayNames.get(internalStatus) || '-'
  }
}

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(dateString: string): string {
    return DateUtils.formatDateString(dateString)
  }
}
@Pipe({
  name: 'isBaseDataType'
})
export class IsBaseDataTypePipe implements PipeTransform {
  transform(usersEditDialogtype: UsersEditDialogType): boolean {
    return usersEditDialogtype === UsersEditDialogType.BaseData
  }
}

@Pipe({
  name: 'getUserName'
})
export class GetUserNamePipe implements PipeTransform {
  transform(user: User, fallbackText: string = 'N/A'): string {
    if (user.id != null) {
      return user.lastName + ', ' + user.firstName
    }

    return fallbackText
  }
}

@Pipe({
  name: 'getUserNameFromUserList'
})
export class GetUserNameFromUserListPipe implements PipeTransform {
  transform(userId: number, userList: User[], fallbackText: string = 'N/A'): string {
    let user = null;
    if (userId != null) {
      user = userList?.find(userFromList => userFromList.id === userId);
    }
    return user != null ? user.lastName + ', ' + user.firstName : fallbackText;
  }
}

