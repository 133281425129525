<div class="dialog column">
  <div class="row">
    <div class="icon-wrapper">
      <img
        class="icon"
        [src]="infoIcon"
        alt="Customer logo">
    </div>
    <div>
    <span>
      Sollen die Stunden des Themas &nbsp;
    </span>
      <span class="highlight">„{{dialogData.mergedTopic.customer.name}}&nbsp;/&nbsp;{{dialogData.mergedTopic.assignment.name}}
        &nbsp;/&nbsp;{{dialogData.mergedTopic.topic.name}}“&nbsp;
    </span>
      <div *ngIf="dialogData.selectedUser != null" class="row">
        <span>von</span>
        <span class="highlight" *ngIf="dialogData.selectedUser">&nbsp;„{{dialogData.selectedUser.name}}“&nbsp;</span>
      </div>
      <span>auf das Thema</span>
      <span class="highlight">
      &nbsp;„{{dialogData.selectedTopic.label}}&nbsp;/{{dialogData.selectedTopic.subLabel}}“&nbsp;
    </span>
      <span>&nbsp;umgebucht werden?</span>
      <br>
      <br>
      <span class="highlight black">
      Dieser Vorgang kann nicht rückgängig gemacht werden!
    </span>
    </div>
  </div>
  <div class="button-bar">
    <button (click)="cancel()" mat-button>Abbrechen</button>
    <button (click)="rebook()" color="primary" mat-raised-button>Verbindlich umbuchen</button>
  </div>
</div>
